import React from 'react';
import { graphql } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';
import { CTACard, animateObjects, newContent, ListTLinkWithDefaults } from "../../components/Layout";
import SEO from "../../components/seo";
import GiveContentTrans from "../../components/GiveContentTrans";

import UIUXVideo from '../../assets/booking-ui-ux.mp4';

import Layout from '../../components/Layout';

// markup // const IndexPage = ({ data }) => {

function AboutPage() { 
  return ( 
    <Layout>
  <main className="open-service-page outer-grid"> <GiveContentTrans language="et"> 
  <SEO
          title="UI/UX disain Eestis, mis teeb kasutajad õnnelikuks"
          description="Moodne ja kasutajasõbralik UI/UX disain veebi ja mobiilirakendustele. Disainime ja teeme prototüüpe. Vaata lähemalt ja võta ühendust"
          image="/pagecover-teenused.jpg"
        /> </GiveContentTrans> <GiveContentTrans language="en"> <SEO
          title="UI/UX Design from Estonia that causes user satisfaction"
          description="Our user-centric UI/UX design services revolutionize your digital presence. We design dashboards, web and mobile applications. Connect with us today!"
          image="/pagecover-en-default.jpg"
        /> </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>UI/UX disain</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>UI/UX design</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>Moodne ning kasutajasõbralik UI/UX disain on sinu ettevõttele vajalik. Otsid UX/UI disainipartnerit kellelt saad
              UI UX disaini tellida kiiresti ja paindlikult? Erinevalt puhtalt UX disaini (kasutusmugavuse disaini) agentuuridest peame lugu ka moodsast ning tipptasemel visuaalsest disainist. 
              Oleme veendunud, et kehva visuaalse disainiga saab luua ainult keskpärast kasutatavust. Teie kliendid hindavad kvaliteetset ja moodsat UI disaini.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
            UI/UX interfaces designed at Give UI/UX agency are visually appealing and provide a smooth user experience. We are the perfect partner for startups and businesses looking to progress with UI/UX design at a fast pace 
            and focus on results rather than excessive fine-tuning. Unlike UX design focused agencies, we value modern and cutting-edge UI design. 
            Poor visual design can lead to mediocre usability, resulting in reduced overall user experience and customer satisfaction.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">

        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/UI-UX-kodu-automatiseerimis-toolaua-kujundus.jpg"
            alt="Automatiseeritud kodu SAAS töölaua UX UI disain"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/UI-UX-kodu-automatiseerimis-toolaua-kujundus.jpg"
            alt="Home automation SAAS dashboard UX/UI design"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>UI/UX disaini tähendus</h2>
            <p>
              UI ja UX disain on omavahel tihedalt seotud. Eduka digitaalse SaaS töölaua rakenduse disainimisel tuleb tähelepanu pöörata mõlemale. 
              Kehva kasutusmugavusega (UX disainiga) toode ei saa kunagi olla edukas aga ka visuaalselt vananenud või ebaproffessionaalne toode ei jäta brändist usaldusväärset muljet.
              Gives kombineerime UX disaini (kasutusmugavuse) moodsa UI disaini kogemusega. Meid võib isegi kutsuda UIX agentturiks. Kogenud UI/UX disainer kindlustab, et
              teile disainitud lahendused näevad head välja ning on kasutajasõbralikud.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>UI/UX design meaning</h2>
            <p>
            UI and UX design are tightly intertwined, and both are crucial for the successful launch of a digital product. A product with poor usability can never achieve success, while a visually outdated or unprofessional product will also fall short. Unlike agencies that solely focus on usability, at Give, we blend contemporary design with extensive experience in crafting user-friendly digital products.
            </p>

       
          </GiveContentTrans>
        </div>
        <div className="content-row">
        <video
              width="100%"
              height="auto"
              title="UI/UX disain"
              autoPlay
              loop
              muted
              playsInline
              poster=""
            >
              <source src={UIUXVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            </div>
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>UX disain (user experience) ehk kasutusmugavuse disain</h3>
              <p>
              UX disain keskendub kasutuskogemuste kujundamisele, et muuta tooted ja teenused kasutajasõbralikuks, kasutatavaks ja nauditavaks. See hõlmab kasutajakeskset lähenemist, kus UX disainerid uurivad, analüüsivad ja küsivad kasutajate vajadusi ning loovad selle põhjal kasutajaliideseid ja interaktsioone.
              </p>
              <p>
              UX disaini eesmärk on tagada sujuv ja meeldejääv kasutajakogemus, mis vastab nii kasutajate ootustele kui ka ettevõtte eesmärkidele. Veebirakenduste ja kasutajaliideste puhul ähendab see seda, et UX disain keskendub sellele kuidas kõik töötab. Milline info on ekraanil, mis järjekorras seda esitletakse. Millised sammud peab kasutaja läbima ja mis järjekorras. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">

            <h3>UX Design (User Experience Design)</h3>
<p>
UX design focuses on crafting user experiences that make products and services user-friendly, usable, and enjoyable. It involves a user-centered approach, where designers research, analyze, and understand user needs to create user interfaces and interactions based on that understanding.
</p>
<p>
The goal of UX design is to ensure a smooth and memorable user experience that aligns with both user expectations and business objectives. In the context of web applications and user interfaces, this means that UX design focuses on how everything works: what information is presented on the screen, the order in which it is presented, and the sequence of steps a user must take.
</p>

            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>UI disain (User Interface) ehk kasutajaliidese disain</h3>
              <p>
              UI disain keskendub kasutajaliidese kujundamisele, et muuta tooted ja teenused visuaalselt atraktiivseks, funktsionaalseks ja usaldusväärseks. See hõlmab disainerite tööd paigutuse, värvide, kirjatüüpide ning nende suuruste, ikoonide ja muude visuaalsete elementidega, et luua brändile sobilik kasutajaliides.</p>

              <p>
              UI disaini eesmärk on luua visuaalseid lahendusi, mis toetavad UX disaini ning pakuvad meeldivat kasutuskogemust. 
              Veebirakenduste ja kasutajaliideste puhul tähendab see seda, et UI disain keskendub sellele kuidas kõik välja näeb. Mis värve ja fonte on kasutatud. Millist gridi on kasutatud ja kui suured vahed on erinevate sisu elemendid ning nende vahed.
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>UI Design (User Interface Design)</h3>
<p>
UI design focuses on designing user interfaces that make products and services visually appealing, functional, and trustworthy. It involves the work of designers on layout, colors, typography, sizes, icons, and other visual elements to create a user interface that aligns with the brand.
</p>

<p>
The goal of UI design is to create visual solutions that support UX design and provide a pleasant user experience. In the context of web applications and user interfaces, this means that UI design focuses on how everything looks: the colors and fonts used, the grid system applied, and the spacing between different content elements and their margins.
</p>
            </GiveContentTrans>
          </div>
        </div>
      </section>
      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>UI/UX disaini tasub investeerida</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>UI/UX design is worth the investment</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
              UX/UI disaini tasub investeerida, kuna see mängib olulist rolli kasutajate rahulolu ja pikaajalise edu tagamisel digitaalsetes toodetes ja teenustes. Hea kasutuskogemus suurendab kasutajate kaasatust, parandab konversioonimäärasid ning aitab luua tugevat ja positiivset brändikuvandit. Investeerimine UX/UI disaini võimaldab ettevõtetel eristuda turul, pakkuda konkurentsieelist ning pikaajalist klientide lojaalsust. Sel moel aitab see tagada edukat ja kasumlikku äritegevust.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <p>
Investing in UX/UI design is worthwhile as it plays a significant role in ensuring user satisfaction and long-term success in digital products and services. A good user experience increases user engagement, improves conversion rates, and helps build a strong and positive brand image. Investing in UX/UI design enables companies to differentiate themselves in the market, gain a competitive advantage, and foster long-term customer loyalty. In this way, it contributes to successful and profitable business operations.
</p>

            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/UI-UX-disain-climatetech-b2b-platvormile.jpg"
                alt="Kliimatehnoloogia töölaua UI/UX disain"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/UI-UX-disain-climatetech-b2b-platvormile.jpg"
                alt="Climatetech dashboard UI/UX design"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>B2B UX/UI disainid</h3>
              <p>
                Suur osa UI disaini projektidest mille kallal töötame on paljudele veebikasutajale kättesaamatu. B2B suunaga veebitarkvara puhul võib mõelda, et UI/UX disain pole oluline 
                kuna enamik seda ei näe. Kliendid on olemas ja kasutavad meie toodet nagunii. See ei ole aga tõsi. 
              </p>
              <p>
              Visuaalselt modernsed ja läbimõeldud UI/UX disain suurendab ka ettevõtte enda töötajate rahulolu. 
              Oskuslikult disainitud ning kasutajasõbralik SaaS tarkvara hoiab kokku töötajate aega. Samamoodi võib läbi planeeritud kasutusmugavuse (UX disaini) vähendada vigade tekkimist. 
              Klientidele suunatud B2B rakenduste puhul võib oluliselt ettevõtte kulusid mõjutada ka nö klienditoe pakkumise maht. Kui UX disain on selge siis puudub lõppkasutajal ka vajadus küsida lisainformatsiooni.          
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>B2B UI/UX Design Solutions</h3>
<p>
A significant portion of the UI design projects we work on are not visible to the average web user. When it comes to B2B web software, one might think that design is not important because most people won't see it. However, that is not entirely true. 
</p>
<p>
Visually modern and well-thought-out design solutions also increase employee satisfaction. A skillfully crafted and user-friendly interface saves employees' time and can reduce the occurrence of errors through thoughtful usability. In the case of B2B applications targeted towards clients, the volume of customer support provided can significantly impact a company's expenses. Clear design eliminates the need for end-users to seek additional information, thus reducing support inquiries.
</p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>B2C UI/UX disainid</h3>
              <p>
              Tavatarbijale suunatud UI/UX disainilahenduste kasutamine võimaldab tööprotsesse automatiseerida ja hoida kokku nii enda kui lõppkasutaja aega. 
                Planeeritud kasutajasõbraliku disainiga süsteemid ja protsessid vähendavad vigade hulk ning suurendavad kliendi rahulolu.               </p>
              <p>
              Kasutajaliideste disainimisel (UI disain) kehtivad natukene teised reeglid kui kodulehtede disainis. Enamasti on esitatava informatsiooni hulk teine ja kasutaja peab süsteemi kaudu sooritama teatud tegevusi või saama tagasisidet toimunud sündmustele. 
              Paljud digitaalsed tooted võistlevad omavahel kasutajate pärast. Kui toote hinna ja äriloogika on paigas siis on just bränd, kasutusmugavus (UX disain) ja visuaalne väljanägemine (UI disain) see mis võib tugeva kliendisuhte luua. Siinkohal ei 
              ole alati kõige targem olla UI/UX disaini tellida veebiagentuurist kellel puudub keerukamate süsteemide kujundamise kogemus. 
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>B2C UI/UX Design Solutions</h3>
<p>
Utilizing design solutions targeted towards everyday consumers allows for the automation of workflows and saves time for both the company and end-users. Thoughtfully planned systems and processes reduce errors and increase customer satisfaction.
</p>
<p>
When designing user interfaces for B2C applications, slightly different rules apply compared to website design. The amount of information presented is often different, and users must perform specific actions or receive feedback through the system. Many digital products compete for users' attention. Once pricing and business logic are in place, it is the brand, usability, and visual appearance that can create a strong customer relationship.
</p>
            </GiveContentTrans>
          </div>
        </div>
    
        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Kodulehtede valmistamine
            </CTACard>
            <CTACard
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Logo ja brändiraamatu kujundamine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Website design&development
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>UI/UX Disainisüsteemid</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Design systems</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
              Disainisüsteem (Design system) on struktureeritud  disaini juhiste, keelekasutuse, korduvkasutatavate elementide, komponentide ja põhimõtete kogum, mida kasutatakse UI/UX disainiprotsessis. See aitab tagada UI kasutajaliideste visuaalset järjepidevust, tõhustada koostööd ui ja ux disainerite vahel ning kiirendada uute toodete ja teenuste arendamist. Disainisüsteem võimaldab efektiivsemalt hallata disainielemente, nagu värvid, fondid, ikoonid, nupud ja vormid, ning tagab, et need on ühtlased ja kvaliteetsed kogu organisatsiooni erinevates rakendustes ja platvormides.
              </p>
              <p>
                Ühel ettevõttel võib olla ka mitu disainisüsteemi või disainisüsteemide kogum eri teenuste ja toodete vahel. Kõige lihtsam näide võiks olla see, et turundusveeb ja digitoode kasutavad erinevat disainisüsteemi. Mõlemal võib olla ühisosa aga spetsiifikast lähtudes erinev lähenemine. 
                Sageli aetakse segamini disainisüsteemi ja kasutajaliidese komponentide kogumikku (UI kit). 
              </p>
              <p>Disainisüsteemi loomisele peaks mõtlema siis kui ühe projekti või tootega töötab mitu erinevat ui või ux disainerit. </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
              A design system is a structured collection of reusable elements, components, guidelines, and principles used in the design process. It helps ensure visual consistency, enhance collaboration among design teams, and accelerate the development of new products and services. A design system allows for more efficient management of design elements, such as colors, fonts, icons, buttons, and forms, ensuring they are consistent and of high quality across various applications and platforms within an organization.
              </p>
              <p>
A company may have multiple design systems or a collection of systems for different services and products. The simplest example could be that the marketing website and digital product utilize different design systems. While they may have some commonalities, each system may have a distinct approach based on specific requirements.
The terms design system and UI kit (user interface kit) are often confused with each other.
</p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/UI-UX-disain-arsti-broneerimistoolaud-broneerimisvaade.jpg"
                alt="UI/UX disain arstile broneerimis süsteemist"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/UI-UX-disain-arsti-broneerimistoolaud-broneerimisvaade.jpg"
                alt="UI/UX design of medical booking dashboard"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/UI-kit.jpg"
                alt="UI kit ehk kasutajaliidese komponentide kogumi näide ≠ disainissüsteem"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/UI-kit.jpg"
                alt="UI-kit ≠ design system"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
              Korraliku UI/UX disainisüsteemi loomine on hulk disainitööd. Enamus Give klientidest ei saa disainisüsteemi kasutamisest täielikult kasu ja pigem soovitame investeerida korralikku UI teema loomisse.
                Kui teie ettevõttel on disainisüsteem olemas siis oskab meie UI/UX disainer sellega tööd teha ja vajadusel seda uute komponentide ja kasutusmugavuse mustritega täiendada.
                Nõu ja jõuga oleme oma kompetentsiga nõus abistama 
                disainisüsteemide loomises kus saame katta UI või UX disainile keskenduvat osa või aidata analüüsida olemasolevat disainisüsteemi tervikuna.
              </p>
              <p>
                UI-kitide disainimisega oleme hea meelega nõus aitama. Üldiselt kujundab meie UI disainer Figmas aga soovi korral võib kasutada ka Sketchi või muud disainitarkvara. Saame aidata ka Reactil põhineva front-end UI-kiti loomisega. 
              </p>

            </GiveContentTrans>
            <GiveContentTrans language="en">
            <p>
At Gives, we typically do not create new design systems. Most of our clients do not yet benefit from using a design system. Additionally, creating a design system is a significant amount of work and can be overwhelming for a small digital agency. However, if your company already has a design system in place, we can work with it and help enhance it with new components and patterns if needed. We are ready to provide our expertise and support in creating design systems, focusing on UI design or analyzing an existing design system.
</p>
<p>
We can assist in creating UI kits. Typically, we create UI kits in Figma, but we can also use Sketch or discuss the creation of a React-based front-end kit, depending on your preferences.
</p>

            </GiveContentTrans>
          </div>
        </div>
      </section>

      <section className="description-section inner-grid">

      <div className="media">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/Dashboard-UIUX-disain.png"
                alt="Töölaua UI UX disain"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/Dashboard-UIUX-disain.png"
                alt="Dashboard UI UX design"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>

        <div className="content-col page-copy">


          <GiveContentTrans language="et">
            <h2>Aitame teid UI/UX disainiga</h2>
            <p>
            Pakume UI/UX terviklahendust teie disainivajadustele. Selgitame välja teie kasutajate vajadused ning pakkume välja optimaalse disainiprotsessi. 
            Kogenud UI/UX disainer kindlustab selle, et lõpplahendus on läbimõeldud ning otstarbekas. 
            Lõpptulemusena loome unikaalsed disainid mis on spetsiaalselt valmistatud probleemi lahendamiseks ja sobituvad teie brändiga. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
          <h2>We Assist You with UI/UX Design</h2>
<p>
We provide comprehensive UI/UX solutions according to your design needs. We explore your users' requirements and propose an optimal design process. As a result, we create unique design solutions specifically tailored to address challenges and align seamlessly with your brand.
</p>

          </GiveContentTrans>
        </div>
      </section>
    </main>

    </Layout>
); }

export default AboutPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;